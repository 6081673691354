<template>
  <div class="container">
    <!-- 默认显示 -->
    <div class="header" v-if="!isSort">
      <div class="left_box">
        <span>选择项目</span>
        <el-cascader
          v-model="selected"
          :options="options"
          :props="{
            expandTrigger: 'hover',
            label: 'name',
            value: 'id',
          }"
          @change="getSysList"
        ></el-cascader>
      </div>
      <div class="right_box" v-if="selected.length">
        <img
          :class="{
            rotate: rotate,
          }"
          @click="refresh"
          src="../../../assets/refresh.png"
        />
        <div class="name">{{ getName }}</div>
        <el-button type="primary" plain @click="isSort = true">手动排序</el-button>
      </div>
    </div>
    <!-- 排序显示 -->
    <div class="sort_header" v-else>
      <div class="tip">
        <img src="../../../assets/clock2.png" alt="" />
        <span>鼠标拖动卡片进行排序</span>
      </div>
      <el-button style="margin-left: 23px" @click="sort(false)">取消</el-button>
      <el-button type="primary" @click="sort(true)">确定</el-button>
    </div>

    <div class="system_container">
      <template v-if="deviceList.length">
        <div
          class="sys_box"
          v-for="item in deviceList"
          :key="item.id_device"
          :draggable="isSort"
          :data-sort-id="item.id"
          @dragstart="dragstart"
          @dragenter="dragenter"
          @dragend="dragend"
          @click="toSysBoard(item)"
        >
          <div class="head">
            <div class="name_box">
              <img
                v-if="item.heater_device_type === 1"
                class="type"
                src="../../../assets/type_1.png"
              />
              <img v-else class="type" src="../../../assets/type_2.png" />
              <div class="name">{{ item.name }}</div>
              <img
                v-if="item.is_heating"
                src="../../../assets/fire.png"
                alt=""
                class="fire"
              />
            </div>
            <div class="time">{{ item.time_update || "--" }}</div>
            <div class="state" v-if="item.state === 1">在线</div>
            <div class="state2" v-else>离线</div>
          </div>

          <div class="content">
            <div class="left_box">
              <div class="item">
                <div class="val_box">
                  <div class="val1">
                    {{
                      item.box_info.level_index !== null ? item.box_info.level_per : "--"
                    }}
                  </div>
                  <div class="unit">%</div>
                </div>
                <div class="tip">水位</div>
              </div>

              <div class="item">
                <div class="val_box">
                  <div class="val2">
                    {{
                      item.box_info.temp_index !== null ? item.box_info.temp_data : "--"
                    }}
                  </div>
                  <div class="unit2">℃</div>
                </div>
                <div class="tip">水箱水温</div>
              </div>

              <div class="item">
                <div class="val_box">
                  <div class="val1">
                    {{
                      item.pipe_info.water_pump_index !== null
                        ? item.pipe_info.water_pump_data
                        : "--"
                    }}A
                  </div>
                  <div class="unit">
                    /{{ item.pipe_info.is_water_pump ? "开" : "关" }}
                  </div>
                </div>
                <div class="tip">供水</div>
              </div>

              <div class="item">
                <div class="val_box">
                  <div class="val1">
                    {{
                      item.pipe_info.back_water_index !== null
                        ? item.pipe_info.back_water_data
                        : "--"
                    }}
                  </div>
                  <div class="unit">℃</div>
                </div>
                <div class="tip">回水温度</div>
              </div>
            </div>

            <div class="temp">
              环境温度：{{ item.temp_index !== null ? item.temp_data : "--" }}
            </div>
          </div>
        </div>
      </template>

      <div class="no_device" v-else>
        <img src="../../../assets/no_sys.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import { GetProjectListV2Api, GetSysInfoListApi, SortDeviceApi } from "@/api";
import DeepClone from "@/utils/DeepClone.js";

export default {
  name: "system_preview",
  data() {
    return {
      selected: [],
      options: [],
      deviceList: [],
      // 是否显示旋转小标
      rotate: false,
      // 是否处于排序中
      isSort: false,
      // 拖拽源
      dragStartResorce: "",
      // 拖拽目标
      dragEndResorce: "",
    };
  },
  methods: {
    // 获取项目列表
    async getProjectList() {
      const params = {};
      const { ret, data, msg } = await GetProjectListV2Api(params);
      if (ret !== 0) {
        return this.$message.error(msg);
      }
      const role = this.$store.state.user.role;
      const options = [];
      if (role === 100) {
        // 超级管理员
        data.map((i) => {
          if (i.project.length) {
            const temp = {
              id: i.id_operator,
              name: i.name_operator,
              children: [],
            };
            i.project.map((j) => {
              temp.children.push({
                id: j.id,
                name: j.name,
              });
            });
            options.push(temp);
          }
        });
        this.options = options;
      } else {
        const options = [];
        data.map((i) => {
          options.push({
            id: i.id,
            name: i.name,
          });
        });
        this.options = options;
      }
    },

    // 刷新系统数据
    async refresh() {
      this.rotate = true;
      this.getSysList();
      setTimeout(() => {
        this.rotate = false;
      }, 400);
    },

    // 选中项目, 获取系统列表
    async getSysList() {
      const params = {
        id_project: this.selected[this.selected.length - 1],
      };
      const { ret, data, msg } = await GetSysInfoListApi(params);
      if (ret !== 0) {
        return this.$message.error(msg);
      }
      if (!data.length) {
        this.deviceList = [];
        this.$message.warning("暂无系统");
      } else {
        this.deviceList = data;
      }
    },

    // 拖拽开始
    dragstart(ev) {
      this.dragStartResorce = ev.target.getAttribute("data-sort-id") / 1;
    },

    // 拖拽进入事件
    dragenter(ev) {
      // 向上查询的深度
      let deep = 0;
      let el = ev.target;
      let replaceId = el.getAttribute("data-sort-id");
      while (deep < 5 && !replaceId) {
        el = el.parentNode;
        replaceId = el.getAttribute("data-sort-id");
        deep++;
      }
      if (replaceId) {
        this.dragEndResorce = replaceId / 1;
      }
    },

    // 查询id索引
    findIndex(tid) {
      return this.deviceList.findIndex((i) => i.id === tid);
    },

    // 拖拽结束
    dragend() {
      // 把index1 和 index2 的元素调换一下
      const index1 = this.findIndex(this.dragStartResorce);
      const index2 = this.findIndex(this.dragEndResorce);
      if (index1 >= 0 && index2 >= 0) {
        const list = [];
        this.deviceList.map((i) => {
          list.push(DeepClone(i));
        });
        const temp = list[index1];
        list[index1] = list[index2];
        list[index2] = temp;
        this.deviceList = list;
      }
    },

    // 排序点击确定
    async sort(isConfirm) {
      if (isConfirm) {
        const idArray = [];
        this.deviceList.map((i) => {
          idArray.push(i.id);
        });
        const params = { id_array: idArray };
        const { ret, data, msg } = await SortDeviceApi(params);
        if (ret !== 0) {
          return this.$message.error(msg);
        }
        this.$message.success("排序成功");
        this.isSort = false;
        this.refresh();
      } else {
        // 点击了取消
        this.isSort = false;
        this.refresh();
      }
    },

    // 点击系统
    toSysBoard(item) {
      if (this.isSort) return;
      this.$store
        .dispatch("tagViews/delCurrentView", {
          path: "/system_info",
          name: "system_info",
        })
        .then(() => {
          const project = this.selected[this.selected.length - 1];
          this.$router.push(
            `/system_info?p=${project}&c=${item.id_device}&tp=${item.heater_device_type}`
          );
        });
    },
  },
  created() {
    this.getProjectList();
  },

  computed: {
    // 计算选中的项目名称
    getName() {
      if (this.selected.length) {
        const obj = this.selected.reduce((pre, cur, idx, lis) => {
          const index = pre.findIndex((i) => i.id === cur);
          return pre[index].children || pre[index];
        }, this.options);
        return obj.name;
      } else {
        return "";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  padding: 0 26px;
}

@keyframes rotate {
  0% {
  }

  100% {
    transform: rotate(180deg);
  }
}

.rotate {
  animation: rotate 0.4s linear;
}

.header {
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .left_box {
    display: flex;
    align-items: center;

    span {
      font-size: 14px;
      font-family: Source Han Sans-Regular, Source Han Sans;
      font-weight: 400;
      color: #3d3d33;
      margin-right: 6px;
    }
  }

  .right_box {
    display: flex;
    align-items: center;

    img {
      width: 20px;
      height: 20px;
      margin-right: 8px;
    }

    .name {
      font-size: 16px;
      font-family: Source Han Sans-Bold, Source Han Sans;
      font-weight: 700;
      color: #1b2351;
      margin-right: 18px;
    }
  }
}

.sort_header {
  height: 64px;
  display: flex;
  align-items: center;

  .tip {
    flex: 1;
    height: 32px;
    display: flex;
    align-items: center;
    background: #fff0e2;
    border-radius: 2px 2px 2px 2px;

    img {
      width: 16px;
      height: 16px;
      margin: 0 8px 0 12px;
    }

    span {
      font-family: Source Han Sans, Source Han Sans;
      font-weight: 400;
      font-size: 14px;
      color: #3d3d3d;
    }
  }
}

.system_container {
  min-height: calc(100vh - 180px);
  background-color: #fff;
  border-radius: 4px;
  padding: 12px;
  align-content: flex-start;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));
  gap: 18px;

  .sys_box {
    height: 220px;
    background: linear-gradient(180deg, #eff2f7 0%, #ffffff 100%);
    box-shadow: 0px 4px 10px 0px rgba(42, 71, 176, 0.06);
    border-radius: 12px;
    overflow: hidden;
    cursor: pointer;

    .head {
      position: relative;
      height: 72px;
      background: linear-gradient(
        270deg,
        rgba(42, 71, 176, 0.1) 0%,
        rgba(255, 255, 255, 0) 100%
      );
      box-shadow: 0px 4px 10px 0px rgba(42, 71, 176, 0.06);
      overflow: hidden;

      .name_box {
        display: flex;
        align-items: center;
        margin: 11px 0 0 15px;
        padding-right: 70px;

        .type {
          width: 34px;
          height: 34px;
        }

        .name {
          flex: 1;
          font-size: 18px;
          font-family: Source Han Sans-Bold, Source Han Sans;
          font-weight: 700;
          color: #2a47b0;
          margin: 0 4px 0 8px;
          line-height: 24px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .fire {
          width: 14px;
          height: 14px;
        }
      }

      .time {
        font-size: 12px;
        font-family: Source Han Sans-Regular, Source Han Sans;
        font-weight: 400;
        color: #3d3d3d;
        line-height: 24px;
        margin-left: 15px;
      }

      .state {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 2;
        width: 70px;
        height: 36px;
        background: #2a47b0;
        border-radius: 0px 12px 0px 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-family: Source Han Sans-Regular, Source Han Sans;
        font-weight: 400;
        color: #ffffff;
      }

      .state2 {
        @extend .state;
        background-color: #ff5757;
      }
    }

    .content {
      height: 148px;
      background: url("../../../assets/sysbg.png") no-repeat;
      background-position: right bottom;
      background-size: 216px 110px;
      display: flex;
      justify-content: space-between;

      .left_box {
        flex: 0 0 206px;
        display: flex;
        flex-wrap: wrap;

        .item {
          flex: 0 0 50%;
          height: 74px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .val_box {
            display: flex;
            align-items: flex-end;

            .val {
              font-size: 20px;
              font-family: Source Han Sans-Bold, Source Han Sans;
              font-weight: 700;
              line-height: 24px;
            }

            .val1 {
              @extend .val;
              color: #3d3d3d;
            }

            .val2 {
              @extend .val;
              color: #ff5757;
            }

            .unit {
              font-size: 12px;
              color: #3d3d3d;
              line-height: 1.4;
            }

            .unit2 {
              font-size: 12px;
              color: #ff5757;
              line-height: 1.4;
            }

            .unit {
              font-size: 14px;
              color: #3d3d3d;
              line-height: 1.4;
            }
          }

          .tip {
            font-size: 12px;
            font-family: Source Han Sans-Regular, Source Han Sans;
            font-weight: 400;
            color: #767676;
            line-height: 24px;
            margin-top: 2px;
          }
        }
      }

      .temp {
        font-size: 14px;
        font-family: Source Han Sans-Regular, Source Han Sans;
        font-weight: 400;
        color: #3d3d3d;
        line-height: 24px;
        margin: 14px 21px 0 0;
      }
    }
  }

  .no_device {
    width: 100%;
    min-height: calc(100vh - 200px);
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    padding-bottom: 40px;

    img {
      width: 400px;
      height: 336px;
    }
  }
}
</style>
