function type(data) {
  let str = Object.prototype.toString.call(data)
  str = str.replace(/\[|\]/g, '')
  str = str.split(' ')[1]
  return str
}

function each(baseData, cpData) {
  if (type(baseData) === 'Array') {
    baseData.map(i => {
      if (type(i) === 'Array') {
        const temp = []
        cpData.push(temp)
        each(i, temp)
      } else if (type(i) === 'Object') {
        const temp = {}
        cpData.push(temp)
        each(i, temp)
      } else {
        cpData.push(i)
      }
    })
  } else if (type(baseData) === 'Object') {
    Object.keys(baseData).map(i => {
      if (type(baseData[i]) === 'Array') {
        const temp = []
        cpData[i] = temp
        each(baseData[i], temp)
      } else if (type(baseData[i]) === 'Object') {
        const temp = {}
        cpData[i] = temp
        each(baseData[i], temp)
      } else {
        cpData[i] = baseData[i]
      }
    })
  }
  return cpData
}

function DeepClone(data) {
  let _data = null
  if (type(data) === 'Array') {
    _data = []
  } else if (type(data) === 'Object') {
    _data = {}
  } else {
    throw new Error(
      'DeepClone function only receive arrays or objects as parameters '
    )
  }
  const res = each(data, _data)
  return res
}

export default DeepClone
